
<template>
  <div class="login"  style="margin-top:100px;">
    <s-header :name="type == 'login' ? 'Login' : 'Register'" :back="'/home'"></s-header>
    <div v-if="type == 'login'" class="login-body login">
      <van-form @submit="onSubmit">
        <van-field
          v-model="username"
          name="username"
          label="Username"
          placeholder="username"
          :rules="[{ required: true,
             pattern: /^\d{8,20}$/,
            message: 'Please enter correct phone number' }]"
        />
        <van-field
          v-model="password"
          type="password"
          name="password"
          label="Password"
          placeholder="password"
          :rules="[{ required: true, 
            pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,16}$/,
            message: 'the password consists of 8 to 16 characters, including numbers, lowercase letters, uppercase letters and special symbols'}]"
        />
        <div class="verify">
          <Verify ref="loginVerifyRef" @error="error"  
          :showButton="false" 
           @success="success" 
           :width="'100%'" 
           :height="'40px'" 
           :fontSize="'16px'" 
          
           :type="0">
        </Verify>

        </div>

      
        <div style="margin: 16px;">
          <div class="link-register" @click="toggle('register')">Register</div>
          &nbsp; &nbsp; &nbsp; &nbsp;
          <div class="link-register" >
            <router-link to="/loginForget" style="color:red">Forget You Password</router-link>
          </div>
          <van-button round block type="info" color="#1baeae" native-type="submit">Login</van-button>
        </div>
      </van-form>
    </div>


    <!------注册------->
    <div v-else class="login-body register" >
      <van-form @submit="onSubmit">
        <van-field
          v-model="username1"
          name="username1"
          label="Username"
          placeholder="username"
          :rules="[{ required: true, 
            pattern: /^\d{8,20}$/,
            message: 'Please enter correct phone number' }]"
        />

        <van-field
          v-model="useremail"
          name="useremail"
          label="useremail"
          placeholder="useremail"
          :rules="[{ required: true, 
          pattern: /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/,
          message: 'please enter a correct email address'}]"
        />

        <van-field
          v-model="password1"
          type="password"
          name="password1"
          label="password"
          placeholder="password"
          :rules="[{ required: true, 
            pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,16}$/,
            message: 'the password consists of 8 to 16 characters, including numbers, lowercase letters, uppercase letters and special symbols'}]"
        />


        
    
        <div class="verify">
          <Verify ref="loginVerifyRef" @error="error" :showButton="false"  @success="success" :width="'100%'" :height="'40px'" :fontSize="'16px'" :type="0" ></Verify>
        </div>


        <div style="margin: 16px;">
          <div class="link-login" @click="toggle('login')">Go To Login</div>
          <van-button round block type="info" color="#1baeae" native-type="submit">Register</van-button>
        </div>
      </van-form>
    </div>


    <div class="good" :style="{ paddingBottom: '90px' }">
      <div style="text-align: center;font-size: 12px;margin-top: 300px;">
        <p>
          © 2023 kuyakan Technology Co.,Limited - All Rights Reserved
        </p>
        <p>
          contac us,send an email to service@kuyakan.com
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import sHeader from '@/components/SimpleHeader'
import { login, register, getUserInfo } from '../service/user'
import { setLocal, getLocal } from '@/common/js/utils'
import { Toast } from 'vant'
import Verify from 'vue2-verify'
export default {
  data() {
    //
    return {
      username: '',
      password: '',
      //
      username1: '',
      useremail:'',
      password1: '',
      type: 'login',
      verify: false
    }
  },
  components: {
    sHeader,
    Verify
  },
  methods: {
    dealTriVer() {
      // 执行验证码的验证，通过 this.verify 知道验证码是否填写正确
      this.$refs.loginVerifyRef.$refs.instance.checkCode()
    },
    toggle(v) {
      this.verify = false
      this.type = v
    },
    async onSubmit(values) {
      //this.dealTriVer()
     // if (!this.verify) {
      //  Toast.fail('code is error!')
       // return
      //}
      if (this.type == 'login') {
        const { data, resultCode } = await login({
          "loginName": values.username,
          "passwordMd5": this.$md5(values.password)
        })
        setLocal('token', data)
        window.location.href = '/'
      } else {
        const { data } = await register({
          "loginName": values.username1,
          "userEmail": values.useremail,
          "password": values.password1
        })
        Toast.success('register sucess')
        this.type = 'login'
      }
    },
    success(obj) {
      this.verify = true
      // 回调之后，刷新验证码
      obj.refresh()
    },
    error(obj) {
      this.verify = false
      // 回调之后，刷新验证码
      obj.refresh()
    }
  },
}
</script>

<style lang="less">
  .login {
    .logo {
      width: 120px;
      height: 120px;
      display: block;
      margin: 80px auto 0px;
    }
    .login-body {
      padding: 0 20px;
    }
    .login {
      .link-register {
        font-size: 14px;
        margin-bottom: 20px;
        color: #1989fa;
        display: inline-block;
      }
    }
    .register {
      .link-login {
        font-size: 14px;
        margin-bottom: 20px;
        color: #1989fa;
        display: inline-block;
      }
    }
    .verify-bar-area {
      margin-top: 24px;
      .verify-left-bar {
        border-color: #1baeae;
      }
      .verify-move-block {
        background-color: #1baeae;
        color: #fff;
      }
    }
    .verify {
      >div {
        width: 100%;
      }
      display: flex;
      justify-content: center;
      .cerify-code-panel {
        margin-top: 16px;
      }
      .verify-code {
        width: 40%!important;
        float: left!important;
      }
      .verify-code-area {
        float: left!important;
        width: 54%!important;
        margin-left: 14px!important;
        .varify-input-code {
          width: 90px;
          height: 38px!important;
          border: 1px solid #e9e9e9;
          padding-left: 10px;
          font-size: 16px;
        }
        .verify-change-area {
          line-height: 44px;
        }
      }
    }
  }
</style>
