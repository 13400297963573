<!--

 *
-->

<template>
  <div class="order-detail-box">
    <s-header :name="'Order details'" @callback="close"></s-header>
    <div class="order-status">
      <div class="status-item">
        <label>Order Status：</label>
        <span>{{ detail.orderStatusString }}</span>
      </div>
      <div class="status-item">
        <label>Order number:</label>
        <span>{{ detail.orderNo }}</span>
      </div>
      <div class="status-item">
        <label>Order time:</label>
        <span>{{ detail.createTime }}</span>
      </div>
      <!-- <van-button v-if="[1,2,3].includes(detail.orderStatus)" style="margin-bottom: 10px" color="#1baeae" block @click="confirmOrder(detail.orderNo)">确认订单</van-button> -->
      <van-button v-if="detail.orderStatus == 0" style="margin-bottom: 10px" color="#1baeae" block @click="showPayFn">GO to pay</van-button>
      <van-button v-if="!(detail.orderStatus < 0 || detail.orderStatus == 4)" block @click="cancelOrder(detail.orderNo)">Cancel the order</van-button>
    </div>
    <div class="order-price">
      <div class="price-item">
        <label>Amount：</label>
        <span>₱ {{ detail.totalPrice }}</span>
      </div>
      <div class="price-item">
        <label>Delivery Method:</label>
        <span>Email</span>
      </div>
    </div>
    <van-card
      v-for="item in detail.newBeeMallOrderItemVOS"
      :key="item.goodsId"
      style="background: #fff"
      :num="item.goodsCount"
      :price="item.sellingPrice"
      desc="card"
      :title="item.goodsName"
      :thumb="prefix(item.goodsCoverImg)"
    />
    <van-popup
      v-model="showPay"
      position="bottom"
      :style="{ height: '24%' }"
    >
      <div :style="{ width: '90%', margin: '0 auto', padding: '20px 0' }">
        <van-button :style="{ marginBottom: '10px' }" color="#1989fa" block @click="payOrder(detail.orderNo, 1)">PAY</van-button>
      </div>
    </van-popup>
  </div>
</template>

<script>
import sHeader from '@/components/SimpleHeader'
import { getOrderDetail, cancelOrder, confirmOrder, payOrder } from '../service/order'
import { Dialog, Toast } from 'vant'
export default {
  components: {
    sHeader
  },
  data() {
    return {
      detail: {},
      showPay: false
    }
  },
  mounted() {
    this.init()

  },
  methods: {
    async init() {
      Toast.loading({
        message: 'Loading...',
        forbidClick: true
      });
      const { id } = this.$route.query
      const { data } = await getOrderDetail(id)
      this.detail = data
      Toast.clear()
    },
    cancelOrder(id) {
      Dialog.confirm({
        title: 'Cacel the order？',
      }).then(() => {
        cancelOrder(id).then(res => {
          if (res.resultCode == 200) {
            Toast('delete success')
            this.init()
          }
        })
      }).catch(() => {
        // on cancel
      });
    },
    confirmOrder(id) {
      Dialog.confirm({
        title: 'Confirm the order？',
      }).then(() => {
        confirmOrder(id).then(res => {
          if (res.resultCode == 200) {
            Toast('confirm success')
            this.init()
          }
        })
      }).catch(() => {
        // on cancel
      });
    },
    showPayFn() {
      this.showPay = true
    },
    async payOrder(id, type) {
      Toast.loading
      await payOrder({ orderNo: id, payType: type }).then(res => {
        let url= res.message;
        //window.location.href=url;
        window.open(url, '_blank');


      }).catch(err => {
        //console.log(err)
      })
      //this.showPay = false
      //this.init()
    },
    close() {
      Dialog.close()
    }
  }
}
</script>

<style lang="less" scoped>
  .order-detail-box {
    background: #f7f7f7;
    .order-status {
      background: #fff;
      margin-top: 44px;
      padding: 20px;
      font-size: 15px;
      .status-item {
        margin-bottom: 10px;
        label {
          color: #999;
        }
        span {

        }
      }
    }
    .order-price {
      background: #fff;
      margin: 20px 0;
      padding: 20px;
      font-size: 15px;
      .price-item {
        margin-bottom: 10px;
        label {
          color: #999;
        }
        span {

        }
      }
    }
    .van-card {
      margin-top: 0;
    }
    .van-card__content {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
</style>
