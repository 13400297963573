<template>
  <div class="address-edit-box">
    <s-header :name="`${type == 'add' ? 'ADD' : 'EDIT'}`"></s-header>
    <van-field style="margin-left: 20px;"
        v-model="userEmail"
        placeholder="please inpu your email"
        label="Email"
      />

    <van-address-edit
      class="edit"
      :disable-area='true'
      :is-phone-valid="true"
      :tel-validator="validator"

      :show-area="false"
      :show-detail='false'
      :area-list="areaList"
      :address-info="addressInfo"
      :show-delete="type == 'edit'"
      show-set-default
      show-search-result
      :search-result="searchResult"
      @save="onSave"
      @delete="onDelete"
     
      />
  </div>
</template>

<script>
import { Toast,Field  } from 'vant'
import sHeader from '@/components/SimpleHeader'
import { addAddress, EditAddress, DeleteAddress, getAddressDetail } from '../service/address'
import { tdist } from '@/common/js/utils'
export default {
  
  components: {
    sHeader
  },
  data() {
    return {
      searchResult: [],
      type: 'add',
      addressId: '',
      addressInfo: {
        userEmail:"",
      },
      from: '',
      userEmail: '', // 新增的输入框
    }
  },
  async mounted() {
    // 省市区列表构造
    const { addressId, type, from } = this.$route.query
    this.addressId = addressId
    this.type = type
    this.from = from || ''
    if (type == 'edit') {
      const { data: addressDetail } = await getAddressDetail(addressId)
      this.addressInfo = {
        id: addressDetail.addressId,
        name: addressDetail.userName,
        tel: addressDetail.userPhone,
        isDefault: !!addressDetail.defaultFlag
        
      }
      this.userEmail=addressDetail.userEmail
    }
  },
  methods: {
    validator(e) {
        return true
    },

    async onSave(content) {
      const params = {
        userName: content.name,
        userPhone: content.tel,
        defaultFlag: content.isDefault ? 1 : 0,
        userEmail:this.userEmail
      }
      
      if (this.type == 'edit') {
        params['addressId'] = this.addressId
      
      }
      const { message } = await this.type == 'add' ? addAddress(params) : EditAddress(params)
      Toast('save success')
      setTimeout(() => {
        this.$router.push({ path: `address?from=${this.from}` })
      }, 1000)

    },
    async onDelete() {
      const { data } = await DeleteAddress(this.addressId)
      Toast('delete success')
      setTimeout(() => {
        this.$router.push({ path: 'address' })
      }, 1000)
    }
  }
}
</script>

<style lang="less">
  @import '../common/style/mixin';
  .address-edit-box {
    margin-top: 44px;
    .van-address-edit {
      .van-button--danger {
        background: @primary;
        border-color: @primary;
      }
      .van-switch--on {
        background: @primary;
      }
    }
  }
</style>
