var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"login",staticStyle:{"margin-top":"100px"}},[_c('s-header',{attrs:{"name":_vm.type == 'login' ? 'Login' : 'Register',"back":'/home'}}),(_vm.type == 'login')?_c('div',{staticClass:"login-body login"},[_c('van-form',{on:{"submit":_vm.onSubmit}},[_c('van-field',{attrs:{"name":"username","label":"Username","placeholder":"username","rules":[{ required: true,
           pattern: /^\d{8,20}$/,
          message: 'Please enter correct phone number' }]},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}}),_c('van-field',{attrs:{"type":"password","name":"password","label":"Password","placeholder":"password","rules":[{ required: true, 
          pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,16}$/,
          message: 'the password consists of 8 to 16 characters, including numbers, lowercase letters, uppercase letters and special symbols'}]},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('div',{staticClass:"verify"},[_c('Verify',{ref:"loginVerifyRef",attrs:{"showButton":false,"width":'100%',"height":'40px',"fontSize":'16px',"type":0},on:{"error":_vm.error,"success":_vm.success}})],1),_c('div',{staticStyle:{"margin":"16px"}},[_c('div',{staticClass:"link-register",on:{"click":function($event){return _vm.toggle('register')}}},[_vm._v("Register")]),_vm._v("         "),_c('div',{staticClass:"link-register"},[_c('router-link',{staticStyle:{"color":"red"},attrs:{"to":"/loginForget"}},[_vm._v("Forget You Password")])],1),_c('van-button',{attrs:{"round":"","block":"","type":"info","color":"#1baeae","native-type":"submit"}},[_vm._v("Login")])],1)],1)],1):_c('div',{staticClass:"login-body register"},[_c('van-form',{on:{"submit":_vm.onSubmit}},[_c('van-field',{attrs:{"name":"username1","label":"Username","placeholder":"username","rules":[{ required: true, 
          pattern: /^\d{8,20}$/,
          message: 'Please enter correct phone number' }]},model:{value:(_vm.username1),callback:function ($$v) {_vm.username1=$$v},expression:"username1"}}),_c('van-field',{attrs:{"name":"useremail","label":"useremail","placeholder":"useremail","rules":[{ required: true, 
        pattern: /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/,
        message: 'please enter a correct email address'}]},model:{value:(_vm.useremail),callback:function ($$v) {_vm.useremail=$$v},expression:"useremail"}}),_c('van-field',{attrs:{"type":"password","name":"password1","label":"password","placeholder":"password","rules":[{ required: true, 
          pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,16}$/,
          message: 'the password consists of 8 to 16 characters, including numbers, lowercase letters, uppercase letters and special symbols'}]},model:{value:(_vm.password1),callback:function ($$v) {_vm.password1=$$v},expression:"password1"}}),_c('div',{staticClass:"verify"},[_c('Verify',{ref:"loginVerifyRef",attrs:{"showButton":false,"width":'100%',"height":'40px',"fontSize":'16px',"type":0},on:{"error":_vm.error,"success":_vm.success}})],1),_c('div',{staticStyle:{"margin":"16px"}},[_c('div',{staticClass:"link-login",on:{"click":function($event){return _vm.toggle('login')}}},[_vm._v("Go To Login")]),_c('van-button',{attrs:{"round":"","block":"","type":"info","color":"#1baeae","native-type":"submit"}},[_vm._v("Register")])],1)],1)],1),_c('div',{staticClass:"good",style:({ paddingBottom: '90px' })},[_vm._m(0)])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"text-align":"center","font-size":"12px","margin-top":"300px"}},[_c('p',[_vm._v(" © 2023 kuyakan Technology Co.,Limited - All Rights Reserved ")]),_c('p',[_vm._v(" contac us,send an email to service@kuyakan.com ")])])
}]

export { render, staticRenderFns }