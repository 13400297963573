
<template>
  <div>
    <div class="about">
      <s-header :name="'ABOUT US'"></s-header>

      <div class="about-body">
        <div>
          <div>
            <div style="width: 90%; margin: auto;line-height:150%;font-size:15px;">
              <div style="text-transform: capitalize;">
                <p>User Agreement</p>
                <ul>
                  <li>
                    1. Service terms agreement
                    This agreement applies to any related services, including upgrades and updates of various services and
                    software used by users during the term of this agreement.
                  </li>

                  <li>
                    2. Service content
                    Provide users with e-commerce website building services, related technical support, network support
                    and
                    other services displayed on the official website.
                  </li>

                  <li>
                    3. Service fees
                    The service fee will be calculated based on the current price on the official website and listed on
                    the
                    purchase page. You must pay the corresponding service fee through the agreed payment method within the
                    agreed payment period.
                    Before you pay the full service fee as agreed, we reserve the right not to provide you with technical
                    support and/or services, and to terminate technical support and/or services.
                    You fully understand all the complimentary services and preferential activities provided in the price
                    system.
                  </li>

                  <li>
                    4. Termination or renewal of paid services
                    After the authorization period of paid services expires, if the corresponding service renewal fails to
                    be
                    purchased in time, the user service will be automatically terminated.
                  </li>
                  <li>
                    5. Rules of use
                    In order to effectively protect your legitimate rights and interests, you understand and agree to
                    accept
                    the
                    following rules:
                    You understand and agree that Yikey Internet unilaterally modifies the relevant rules of the service
                    without
                    obtaining your consent. The service rules shall be based on the page prompts when you use the service.
                    Your
                    agreement and compliance with the service rules is a prerequisite for your use of the platform.
                    EasyKey Internet will notify you of the service progress and prompt you to take the next step via SMS
                    or
                    email, but EasyKey Internet does not guarantee that you will be able to receive the SMS or email in
                    time,
                    and will not bear any consequences for this.
                    Therefore, you should log in to this website in time to view and perform relevant operations during
                    the
                    service process.
                    Yikey Internet does not bear any responsibility for any disputes or losses caused by your failure to
                    check
                    and modify or confirm the service status in a timely manner or your failure to submit relevant
                    applications.
                    When you use it, we have the right to charge you service fees in accordance with the corresponding
                    service
                    charging introduction, order or related agreement. The specific service charges are subject to the
                    charging
                    method announcement listed on this website when you use it or other written agreements reached between
                    you
                    and Yikey Internet.
                    When you violate Terms E, we have the right to refuse to continue to provide you with any services.
                  </li>
                  <li>
                    6. Your rights and obligations
                    You have the right to have your own username and password, and you have the right to use your own
                    username
                    and password to log in at any time.
                    You have the right to enjoy the services provided and the right to receive technical support when
                    accepting
                    the services provided. Please refer to the relevant product introduction for details of the service
                    content.
                    You guarantee that you will not use technical or other means to damage or disrupt other customers'
                    websites
                    and services.
                    You shall respect the intellectual property rights and other legal rights of other third parties, and
                    reserve the right to terminate the service provided to you and refund any money if you infringe upon
                    the
                    legitimate rights and interests.
                    You shall be solely responsible for any consequences caused by the incorrect contact information you
                    provide
                    to us and the poor security and stability of the email address you use to receive emails.
                  </li>
                  <li>
                    7. Dispute resolution
                    If a dispute arises over the interpretation or execution of this Agreement, the parties shall first
                    strive
                    to resolve the dispute through friendly negotiation.
                    If a dispute cannot be resolved through negotiation within sixty days after one party serves the other
                    party
                    with a written notice requesting the commencement of negotiations,
                    Then either party can submit the dispute to the China International Economic and Trade Arbitration
                    Commission Beijing General Assembly for arbitration in accordance with its rules in effect at that
                    time.
                    The arbitration award is final and binding on all parties and cannot be appealed. The costs of
                    arbitration
                    shall be borne by the losing party unless otherwise provided in the arbitration award.
                    When any dispute arises and when any dispute is arbitrated, in addition to the matter in dispute
                    , the parties shall continue to exercise their other rights under this Agreement and perform their
                    other
                    obligations under this Agreement.
                  </li>
                </ul>
              </div>
              <div style="float: right;margin-top: 50px;">

                <p>email:service@kuyakan.com</p>

              </div>

              <div style="margin-top: 50px;">
                <p> &nbsp; &nbsp;</p>
              </div>

            </div>
          </div>
        </div>
      </div>



    </div>

    <div class="good" :style="{ paddingBottom: '30px' }">
      <div style="text-align: center;font-size: 12px;">
        <p>
          © 2023 kuyakan Technology Co.,Limited - All Rights Reserved
        </p>
        <p>
          contac us,send an email to service@kuyakan.com
        </p>
      </div>
    </div>


  </div>
</template>

<script>
import sHeader from '@/components/SimpleHeader'
export default {
  components: {
    sHeader
  },
}
</script>

<style lang="less" scoped>
.about {
  box-sizing: border-box;
  padding: 20px;

  .about-body {
    margin-top: 44px;
    font-size: 16px;

    a {
      color: #007fff;
    }
  }

  .shadowed-box {
    width: 200px;
    height: 100px;
    background-color: #fff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  }

}</style>
